'use client';

import { FC, useMemo, useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Box, Paper, Dialog, TextField } from '@mui/material';

import { IVirtualCard } from 'src/types/club-cards';

import Image from '../../../components/image';
import Iconify from '../../../components/iconify';
import { MainTabs } from '../components/main-tabs';
import { DialogCustom } from '../components/dialog';
import { Greetings } from '../components/greetings';
import { MainBanner } from '../components/main-banner';
import { TabCurrent } from '../components/tab-current';
import { TabHistory } from '../components/tab-history';
import { WalletTables } from '../components/wallet-tables';
import { TabTransfers } from '../components/tab-transfers';
import WalletCardsTab from '../components/wallet-cards-tab';
import { useAppContext } from '../../../contexts/AppContext';
import { TabManagement } from '../components/tab-management';
import { useResponsive } from '../../../hooks/use-responsive';
import { WalletProducts } from '../components/wallet-products';
import { FamilyCards } from '../../../components/family-cards';
import { ComponentTypeProps } from '../../../types/page-generator';
import { getContentValueFromProps } from '../../generate-page/utils';
import { TabClubCardHistory } from '../components/tab-club-card-history';
import { uploadProductsImage } from '../../../helpers/uploadProductsImage';
import { TabTransactionHistory } from '../components/tab-transaction-history';
import { P2PDialog } from '../components/p2p-dialog';
import { getVirtualCards } from '../../../api/clubCards';
import { fetchOneCustomer, getCustomerByPhoneOrMail } from '../../../api/customers';
import { useAuthContext } from '../../../auth/hooks';
import { ICustomerItem } from '../../../types/customer-citypeople';
import { useLocales, useTranslate } from '../../../locales';

const WalletView: FC<ComponentTypeProps> = ({ block, params }) => {
  const { currentLang } = useLocales();
  const { t } = useTranslate();
  const { user } = useAuthContext();
  const { dispatch, state } = useAppContext();
  const [customer, setCustomer] = useState<ICustomerItem[] | any>([]);
  const sectionType = params.section.sectionType;
  const isMobile = useResponsive('down', 'sm');
  const getFieldValue = getContentValueFromProps(block);
  const [activeTitle, setActiveTitle] = useState(0);
  const [activeTabHistory, setActiveTabHistory] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [openProduct, setOpenProduct] = useState<IVirtualCard | null>(null);
  const [openP2P, setOpenP2P] = useState<IVirtualCard | null>(null);
  const [openHistory, setOpenHistory] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [clubCardInfo, setClubCardInfo] = useState(false);
  const activityId = state.smbActivityId || '';
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setActiveTab(2);
  }, [clubCardInfo]);

  const [minValue, setMinValue] = useState<number | string>('');
  const [email, setEmail] = useState<number | string>('');
  const [phone, setPhone] = useState<number | string>('');

  // const localCards = [
  //   {
  //     id: '98fd08cf-3c11-436d-bc5f-680534erd32',
  //     type: 'local',
  //     cardNumber: '83058204848694',
  //     balance: '300.00',
  //     isGlobal: true,
  //     cardTypeId: 'a4daf20e-8962-46e7-a79a-167074a250c7',
  //     customerId: '56c6334c-34f4-49b9-b333-4648e70ef062',
  //     cardType: {
  //       id: 'a4daf20e-a994-3fght-a79a-167074a250c7',
  //       name: 'גלובל תו',
  //       description: 'גלובל תו כללי',
  //       mainImageURL: '/assets/images/boom-buy/cards/card_1.png',
  //       deletedDate: null,
  //     },
  //     transactions: [
  //       {
  //         id: 'da41c9dd-a785-46c0-5563-3fe2bd378ba4',
  //         amount: '300.00',
  //         relatedActivityTransactionId: '0a042af0-cbe1-3529-8777-36711633f3c9',
  //         virtualCardId: '98fd08cf-3c11-3698-bc5f-680e5d7324bf',
  //         transactionType: 'CREDIT',
  //       },
  //     ],
  //   },
  //   {
  //     id: '74bec7c5-2281-430d-bc18-2d92124at655',
  //     type: 'local',
  //     cardNumber: '33490518560013',
  //     balance: '100.00',
  //     isGlobal: false,
  //     cardTypeId: '5829e017-129c-4d9e-8962-a548857f2e41',
  //     customerId: '56c6334c-34f4-49b9-b333-4648e70ef062',
  //     cardType: {
  //       id: '5829e017-129c-4d5e-9e2a-a548fg7f2e41',
  //       name: 'תו כספי - כל העולמות בתו אחד!',
  //       description:
  //         "תו כספי דיגיטלי למימוש במאות אתרים בפריסה ארצית: מסעדות, בתי קפה, קונדיטוריות, בתי מלון, צימרים, ספא, אטרקציות, תכשיטים, גאדג'טים, רשתות ביגוד, מוצרי חשמל, תמרוקים, מוזיקה, ספרים, מתנות ועוד…",
  //       mainImageURL: '/assets/images/boom-buy/cards/card_2.png',
  //       deletedDate: null,
  //     },
  //     transactions: [
  //       {
  //         id: '03004047-e69d-fgrt-93f3-71910feft5de',
  //         amount: '100.00',
  //         relatedActivityTransactionId: '623ee1bd-3529-45d8-bf69-74c2a7b0d3c6',
  //         virtualCardId: '74bec7c5-2281-3698-bc18-2d92124ae255',
  //         transactionType: 'CREDIT',
  //       },
  //     ],
  //   },
  // ];

  const {
    state: { virtualCards },
  } = useAppContext();

  const currentCard = virtualCards.find((item: IVirtualCard) => item.id === openHistory);
  const changeTitle = useCallback((id: number) => {
    setActiveTitle(id);
    setOpenProduct(null);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTitle(newValue);
  };

  const handleChangeTabHistory = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabHistory(newValue);
  };

  const changeHistory = (id: string) => {
    if (block?.settings?.enableCardOpenHistory) {
      setOpenHistory(id);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const init = () => {
    if (user) {
      getCustomerByPhoneOrMail(user.phone, user.email, dispatch).then((response: any) => {
        if (response) {
          setLoading(true);
          fetchOneCustomer(response[0]?.id).then((result) => {
            if (result) {
              setLoading(false);
              setCustomer(result.payload);
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    init();
  }, [user]);

  return (
    <Box sx={{ mt: '-20px' }}>
      {!clubCardInfo ? (
        <>
          {openHistory === '' ? (
            <Stack alignItems="center" spacing={3}>
              {block?.settings?.enableTitle && <Greetings block={block} />}
              {block?.settings?.enableAllTabs && (
                <MainTabs
                  changeTitle={changeTitle}
                  handleChange={handleChange}
                  activeTitle={activeTitle}
                  block={block}
                />
              )}
              <MainBanner block={block} />
              {activeTitle === 0 && (
                <WalletCardsTab
                  openProduct={openProduct}
                  setOpenProduct={setOpenProduct}
                  setOpenP2P={setOpenP2P}
                  block={block}
                  openHistory={changeHistory}
                  openModal={openModal}
                  onChangeClubCardInfo={() => {
                    setClubCardInfo(true);
                  }}
                />
              )}
              {activeTitle === 1 && <WalletProducts block={block} />}
              {activeTitle === 2 && <TabHistory />}
              {activeTitle === 3 && <WalletTables block={block} />}
              {activeTitle === 4 && (
                <FamilyCards
                  block={block}
                  customer={customer}
                  loading={loading}
                  refreshData={init}
                  sectionType={sectionType}
                />
              )}
            </Stack>
          ) : (
            <Stack>
              <Stack
                width={1}
                mb={2}
                sx={{ cursor: 'pointer' }}
                alignItems={currentLang.value === 'he' ? 'flex-end' : 'flex-start'}
              >
                <Iconify icon="fluent:ios-arrow-24-filled" onClick={() => setOpenHistory('')} />
              </Stack>
              <Stack gap={2}>
                <Paper
                  elevation={3}
                  sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                >
                  <Image
                    src={
                      currentCard && currentCard?.type === 'local'
                        ? currentCard?.cardType?.mainImageURL
                        : uploadProductsImage(currentCard?.cardType?.mainImageURL || '')
                    }
                    sx={{ borderRadius: '10px', height: '100%', width: !isMobile ? '20%' : '50%' }}
                  />
                  <Typography>
                    {t('Card number')}: {currentCard?.cardNumber}
                  </Typography>
                  {!isMobile && (
                    <Tabs value={activeTabHistory} onChange={handleChangeTabHistory} sx={{ p: 1 }}>
                      <Tab label={<Typography>עובר ושב</Typography>} />
                      <Tab label={<Typography>העבורות</Typography>} />
                      <Tab label={<Typography>ניהול</Typography>} />
                      <Tab label={<Typography>היסטוריית עסקאות</Typography>} />
                    </Tabs>
                  )}
                </Paper>
                <Paper elevation={5}>
                  {activeTabHistory === 0 && <TabCurrent />}
                  {activeTabHistory === 2 && <TabTransfers />}
                  {activeTabHistory === 1 && <TabManagement openModal={openModal} />}
                  {activeTabHistory === 3 && <TabTransactionHistory id={openHistory} />}
                </Paper>
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <Container maxWidth="xs">
          <Stack
            sx={{
              alignItems: 'center',
              margin: 'auto',
              my: 2,
            }}
          >
            <Stack
              width={1}
              alignItems={currentLang.value === 'he' ? 'flex-end' : 'flex-start'}
              mb={2}
              sx={{ cursor: 'pointer' }}
            >
              <Iconify icon="fluent:ios-arrow-24-filled" onClick={() => setClubCardInfo(false)} />
            </Stack>
            <Typography>
              חשבון מספר:
              <b>7482245285702036</b>
            </Typography>
            <Typography>
              תאריך תפוגה:
              <b> 31.07.2027</b>
            </Typography>
          </Stack>
          <Stack gap={2}>
            <Paper
              elevation={3}
              sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            >
              <Tabs value={activeTab} onChange={handleChangeTab} sx={{ p: 1 }}>
                <Tab label={<Typography>יתרת כרטיס</Typography>} />
                <Tab label={<Typography>טעינת כרטיס</Typography>} />
                <Tab label={<Typography>היסטוריה</Typography>} />
              </Tabs>
            </Paper>
            <Paper elevation={5}>
              {activeTab === 0 && <></>}
              {activeTab === 1 && <></>}
              {activeTab === 2 && <TabClubCardHistory />}
            </Paper>
          </Stack>
        </Container>
      )}
      <Dialog
        maxWidth="xs"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        sx={{ '& .MuiPaper-root': { width: '100%' } }}
      >
        <DialogCustom onClose={() => setModalOpen(false)} block={block} />
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={openP2P !== null}
        onClose={() => setOpenP2P(null)}
        sx={{ '& .MuiPaper-root': { width: '100%' } }}
      >
        <P2PDialog
          onClose={() => {
            setOpenP2P(null);
            getVirtualCards(activityId, dispatch);
          }}
          block={block}
          openP2P={openP2P}
          sectionType={sectionType}
        />
      </Dialog>
    </Box>
  );
};
export default WalletView;
